// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/components/kit/core/mixins.scss'; // import KIT mixins
@import 'src/components/cleanui/styles/mixins.scss'; // import CLEANUI mixins

// $text: #000;
// $success: green;

.ant-btn {
    &:hover,
    &:active,
    &:focus {
      border-color: lighten($blue-light, 0%);
      color: lighten($blue-light, 0%);
    }
    &-primary {
      background: $primary !important;
      border-color: $primary !important;
  
      &:hover,
      &:active,
      &:focus {
        background: lighten($orange, 0%) !important;
        border-color: lighten($orange, 0%) !important;
        color: $white;
      }
    }
    &-danger {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 5%);
        border-color: lighten($danger, 5%);
        color: $white;
      }
    }
    &-primary,
    &-danger {
      &[disabled],
      &[disabled]:hover {
        background: $gray-2 !important;
        border-color: $gray-2 !important;
      }
    }
  }

  .kit__utils__link {
    &:hover,
    &:active,
    &:focus {
      color: lighten($orange, 0%) !important;
    }
  }